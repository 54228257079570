<template>
  <div id="mainDiv">
    <div style="height: 6vh; margin-top: 4vh;">
      <img src="logo-gojee.png" style="height: 100%;"/>
    </div>
    <div style="height: 36.7vh; margin-top: 5vh">
      <img src="image01.png" style="height: 100%;"/>
    </div>
    <div style="height: 16.5vh; margin-top: 0vh">
      <a id="link" href="">
        <img src="Gojee.svg" style="height: 100%;"/>
      </a>
    </div>
    <div style="height: 11vh; margin-top: 3vh">
      <a href="http://onelink.to/5uhjzg">
        <img src="downloadios.png" style="height: 100%;"/>
      </a>
    </div>
    <div style="height: 24vh; margin-top: -7vh">
      <a href="http://onelink.to/5uhjzg">
        <img src="downloadandroid.png" style="height: 100%;"/>
      </a>
    </div>
  </div>
</template>

<script>
function try_to_open_app(url) {
  window.location = url;
}

function getParameterByName () {
  let query = `?api_url=${process.env.VUE_APP_API_URL}`
  let fallback = 'http://onelink.to/5uhjzg'

  document.getElementById('link').href += query

  try_to_open_app(document.getElementById('link').href, fallback);
}

window.onload = () => {
  document.getElementById('link').href = process.env.VUE_APP_MOBILE_DEEP_LINK
  getParameterByName();
}
</script>

<style>

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
}
#mainDiv {
  max-width: 600px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  text-decoration: none !important;
}
</style>
